<template>
  <div>
    <b-tabs v-model="selected_tab"
            card>
      <b-card>
        <b-row>
          <b-col style="margin-bottom: 1rem;">
            <call-filter :id="id"
                         :key="refresh"
                         ref="call_filter"
                         v-model="proposals"
                         @load_data="load_ceric_proposals_for"
                         @selected_call_change="call_change"
                         @update_proposals="refresh_proposals"/>
          </b-col>
        </b-row>
        <b-card-header class="mb-1 p-0">
          <b-button v-b-modal.modal_ceric_proposal
                    :disabled="!isUserCericEditor"
                    block
                    variant="info"
                    @click="set_focused_id('')"
                    v-text="createProposalBtnText"/>
        </b-card-header>
        <b-tab active
               class="p-0"
               title="Ceric proposals">
          <div v-if="proposals">
            <b-table :current-page="currentPage"
                     :fields="fields"
                     :items="proposals"
                     :per-page="perPage"
                     head-variant="light"
                     hover
                     responsive
                     striped
                     @row-clicked="setShowFinalSubmit">
              <template v-slot:top-row="{fields}">
                <proposal-filter :id="id"
                                 v-model="proposals"
                                 :fields="fields"
                                 :not_include_key="['actions', 'ordinalNumber']"
                                 :selected_call="selected_call"/>
              </template>

              <template v-slot:cell(ordinalNumber)="row">
                <base-column-ordinal-number v-bind="{index: row.index, perPage, currentPage}"/>
              </template>

              <template v-slot:cell(actions)="row">
                <div @click="setShowFinalSubmit(row.item, undefined, $event)">
                  <action-download-pdf :proposal="row.item"/>

                  <template v-if="is_proposal_editable(row.item)">
                    <base-icon-button-comment v-b-modal.modal_ceric_proposal
                                              :disabled="!isUserCericEditor"
                                              title="Edit proposal"
                                              @click="set_focused_id(row.item._id.$oid)"/>

                    <base-double-click-submit-button :btn-options="submitBtnOptions"
                                                     :disabled="!isUserCericEditor"
                                                     :is-confirmation="isFinalSubmit(row.item)"
                                                     @click="submit_ceric_proposal(row)"/>

                    <base-icon-button-delete v-b-modal.modal_acceptance
                                             :disabled="!isUserCericEditor"
                                             class="red-dark"
                                             title="Delete proposal"
                                             @click="set_focused_row(row)"/>
                  </template>

                  <template v-if="hasTeamMembers(row.item)">
                    <base-action-expand-details :b-table-row="row"
                                                :title="detailsTitle"/>
                  </template>

                </div>
              </template>

              <template v-slot:row-details="row">
                <team_form_no_account v-if="hasTeamMembers(row.item)"
                                      v-model="row.item.document_specification.subtype.ceric.team_members"/>
              </template>
            </b-table>

            <b-table-footer :currentPage="currentPage"
                            :data_length="proposals.length"
                            :perPage="perPage"
                            @change_page="change_page"/>

            <modal_ceric_proposal :object_id="focused_id"
                                  @refresh_dashboard="refresh = !refresh"/>

            <modal_acceptance modal-title="Are you sure you want to delete the proposal?"
                              ok-button-text="Delete proposal"
                              @ok="remove_ceric_proposal(focused_row)"/>
          </div>
        </b-tab>
      </b-card>
    </b-tabs>
  </div>
</template>

<script>
  import ActionDownloadPdf from '@/components/actions/ActionDownloadPdf';
  import BaseActionExpandDetails from '@/components/actions/BaseActionExpandDetails';
  import bTableFooter from '@/components/b_table_footer';
  import BaseIconButtonComment from '@/components/baseComponents/baseIcons/BaseIconButtonComment';
  import BaseIconButtonDelete from '@/components/baseComponents/baseIcons/BaseIconButtonDelete';
  import callFilter from '@/components/call_filter';
  import BaseDoubleClickSubmitButton from '@/components/baseComponents/BaseDoubleClickSubmitButton';
  import modal_acceptance from '@/components/modal_acceptance';
  import modal_ceric_proposal from '@/components/proposal/ceric/modal_ceric_proposal';
  import proposalFilter from '@/components/proposal_filter';
  import team_form_no_account from '@/components/team_form_no_account';
  import submitBtnOptions from '@/json/doubleClickSubmitButton';
  import BaseColumnOrdinalNumber from '@/components/baseComponents/BaseColumnOrdinalNumber';

  export default {
    props: {
      fields: {
        type: Array,
        default: () => [],
      },
      type: {
        type: String,
        default: '',
      },
    },
    components: {
      BaseActionExpandDetails,
      ActionDownloadPdf,
      BaseIconButtonComment,
      BaseIconButtonDelete,
      BaseColumnOrdinalNumber,
      modal_ceric_proposal,
      modal_acceptance,
      team_form_no_account,
      proposalFilter,
      bTableFooter,
      callFilter,
      BaseDoubleClickSubmitButton,
    },
    data() {
      return {
        proposals: [],
        all_proposals_for_call: [],
        selected_call: '',
        id: 0,
        selected_tab: 0,
        currentPage: 1,
        perPage: 20,
        focused_row: {},
        focused_id: '',
        expected_call_states: ['submissionClosed'],
        finalSubmit: '',
        refresh: true,
        createProposalBtnText: 'Create new CERIC proposal',
        submitBtnOptions,
        detailsTitle: {
          hidden: 'Show team',
          shown: 'Hide team',
        },
      };
    },
    computed: {
      isUserCericEditor() {
        const permissions = this.$store.getters['login/getLoggedUserAttribute']('permissions') || [];
        return permissions.includes('EDIT_PROP_CERIC');
      },
    },
    methods: {
      setShowFinalSubmit(proposal, unusedIndex, event) {
        this.finalSubmit = '';
        if (event.target.id === this.submitBtnOptions.attempt.icon.id) {
          this.finalSubmit = proposal._id.$oid;
        }
      },
      isFinalSubmit(proposal) {
        return proposal._id.$oid === this.finalSubmit;
      },
      hasTeamMembers(proposal) {
        return proposal.document_specification.subtype.ceric.team_members;
      },
      load_ceric_proposals_for(call) {
        this.axios.get(`/documents/ceric/${this.type}/${call}`)
          .then((response) => {
            this.proposals = response.data;
            this.$set(this, 'all_proposals_for_call', this.proposals);
            // setup the data in proposal-filter, call-filter component
            this.$emit('setup_proposal', this.proposals);
            // load data from children component
            this.$emit('load_data');
          });
      },
      set_focused_id(object_id) {
        this.focused_id = object_id;
      },
      set_focused_row(row) {
        this.focused_row = row;
      },
      async remove_ceric_proposal(row) {
        const document_id = row.item._id.$oid;
        const { msg, error } = (await this.axios.delete(`/documents/ceric/${document_id}`)).data;
        if (msg) {
          this.overwrite_ceric_proposal(document_id);
          this.$notify({ type: 'success', title: msg });
        } else {
          this.$notify({ type: 'error', title: error });
        }
      },
      overwrite_ceric_proposal(document_id) {
        this.axios.get(`/documents/ceric/${document_id}`)
          .then((response) => {
            if (response.data.document) {
              this.proposal = response.data.document;
              this.update_proposal();
            } else {
              this.$notify({ type: 'error', title: response.data.error });
            }
          });
      },
      submit_ceric_proposal(row) {
        const document_id = row.item._id.$oid;
        this.axios.patch(`/documents/ceric/${document_id}`)
          .then((response) => {
            if (response.data.msg) {
              this.generate_and_add_pdf(document_id);
              this.overwrite_ceric_proposal(document_id);
              this.$notify({ type: 'success', title: response.data.msg });
            } else {
              this.$notify({ type: 'error', title: response.data.error });
            }
          })
          .catch((error) => {
            if (error.response.data.error) {
              this.generate_and_add_pdf(document_id);
              this.overwrite_ceric_proposal(document_id);
              this.$notify({ type: 'error', title: error.response.data.error });
            } else {
              this.$notify({ type: 'error', title: 'Communication error' });
            }
          });
      },
      generate_and_add_pdf(document_id) {
        this.axios.put(`/add_rendered_pdf/${document_id}`)
          .then((response) => {
            if (response.data.msg) {
              this.$notify({ type: 'success', title: response.data.msg });
            } else {
              this.$notify({ type: 'error', title: response.data.error });
            }
          })
          .catch(() => {
            this.$notify({
              type: 'error',
              title: 'PDF cannot be generated, contact SOLARIS User Office, please',
            });
          });
      },
      is_proposal_editable(proposal) {
        const final_state = proposal.states.name;
        const partial_state = proposal.document_specification.subtype.ceric.states.name;
        return [final_state, partial_state].every((state) => state === 'draft');
      },
      refresh_proposals(proposals) {
        this.refresh_proposals_filter(proposals);
        this.$set(this, 'all_proposals_for_call', this.proposals);
      },
      update_proposal() {
        this.proposals.forEach((el, idx) => {
          if (el._id.$oid === this.proposal._id.$oid) {
            this.$set(this.proposals, idx, this.proposal);
          }
        });
      },
      change_page(val) {
        this.currentPage = val;
      },
    },
    watch: {
      selected_tab() {
        this.$emit('refresh');
      },
    },
  };
</script>

<style lang="scss"
       scoped>
  .card {
    margin: 1.25rem;
  }
</style>
