import { render, staticRenderFns } from "./modal_acceptance.vue?vue&type=template&id=671216a2&scoped=true&"
import script from "./modal_acceptance.vue?vue&type=script&lang=js&"
export * from "./modal_acceptance.vue?vue&type=script&lang=js&"
import style0 from "./modal_acceptance.vue?vue&type=style&index=0&id=671216a2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "671216a2",
  null
  
)

export default component.exports