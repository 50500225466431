<template>
  <div>
    <b-modal id="modal_ceric_proposal"
             centered
             no-close-on-backdrop
             title="Proposal for CERIC"
             @hidden="resetModal"
             @show="resetModal">

      <ceric_proposal :object_id="object_id"
                      :save_button_handler="save_button_handler"
                      :touch_validator="save_attempt"
                      @set_validation="handleValidityState($event)"
                      @submission_state="handleSubmissionState($event)"/>

      <template #modal-footer>
        <button-spinner :loading="save_button_handler"
                        class="ma-2 right"
                        variant="info"
                        @click="handleOk">
          <template v-slot:defaultText>
            <span>Save proposal</span>
          </template>
          <template v-slot:spinnerText>
            <span> Saving...</span>
          </template>
        </button-spinner>
      </template>

    </b-modal>
  </div>
</template>

<script>
  import ceric_proposal from '@/components/proposal/ceric/ceric_proposal';
  import buttonSpinner from '@/components/buttonSpinner';

  export default {
    name: 'modal_ceric_proposal',
    components: {
      ceric_proposal,
      buttonSpinner,
    },
    props: {
      object_id: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        validity_state: null,
        save_attempt: false,
        save_button_handler: false,
      };
    },
    methods: {
      handleValidityState(state) {
        this.validity_state = state;
        return state;
      },
      resetModal() {
        this.validity_state = null;
        this.save_attempt = false;
        this.save_button_handler = false;
      },
      handleOk(bvModalEvt) {
        // Prevent modal from closing
        bvModalEvt.preventDefault();
        // Trigger save handler
        this.handleSave();
      },
      handleSave() {
        this.save_attempt = true;
        // Exit when the form isn't valid
        if (!this.validity_state) {
          return;
        }
        this.save_button_handler = true;
      },
      handleSubmissionState(state) {
        if (state) {
          this.hideModalManually();
          this.$emit('refresh_dashboard');
        }
        this.save_button_handler = false;
      },
      hideModalManually() {
        this.$nextTick(() => {
          this.$bvModal.hide('modal_ceric_proposal');
        });
      },
    },
  };
</script>

<style lang="scss"
       scoped>

</style>
