<template>
  <div>
    <form>
      <label>Call name</label>
      <b-form-select v-model="proposal_data.call_id"
                     :class="{ 'invalid': $v.proposal_data.call_id.$error }"
                     :options="calls_options"
                     style="margin-bottom: 15px;"
                     @input="$v.proposal_data.call_id.$touch()"/>

      <div v-for="(value, key) in input_fields"
           :key="key">
        <label>{{ value.label }}</label>
        <span v-if="!value.required"
              class="grey--text"> - Optional</span>
        <b-form-input v-model="proposal_data[key]"
                      :class="{ 'invalid': $v.proposal_data[key].$error }"
                      :disabled="value.disabled || false"
                      :min="value.min"
                      :readonly="false"
                      :type="value.type || 'text'"
                      style="margin-bottom: 15px;"
                      v-bind="value.props"
                      @input="$v.proposal_data[key].$touch()"/>
      </div>
      <div v-if="proposal_data.infrastructure === 'synchrotron'">
        <label>Environment</label>
        <b-form-select v-model="proposal_data.beamlines"
                       :class="{ 'invalid': $v.proposal_data.beamlines.$error }"
                       :options="Object.keys(beamlines_config)"
                       style="margin-bottom: 15px;"
                       @change="proposal_data.end_station = ''"
                       @input="$v.proposal_data.beamlines.$touch()"/>
        <label>End station</label>
        <b-form-select v-model="proposal_data.end_station"
                       :class="{ 'invalid': $v.proposal_data.end_station.$error }"
                       :options="beamlines_config[proposal_data.beamlines]"
                       style="margin-bottom: 15px;"
                       @input="$v.proposal_data.end_station.$touch()"/>
      </div>
      <div>
        <b-form-group
          label="Attachments"
          label-for="attachments"
          label-size="md">
          <label class="grey--text">Use only PDF files.</label>
          <attachment-input id="attachments"
                            v-model="proposal_data.attachments"
                            :attachment_url="attachment_url"
                            class="mt-1"
                            extensions="application/pdf"
                            multiple/>
        </b-form-group>
        <p v-if="!are_attachments && touch_validator"
           class="error_text">
          At least one attachment should be added.
        </p>
      </div>
    </form>
    <h6 v-if="!validation_state && touch_validator"
        class="error_text">
      Fill all required fields to save.
    </h6>
  </div>
</template>

<script>
  import { ceric_proposal_validator } from '@/assets/validators/ceric_proposal_validator';
  import attachmentInput from '@/components/attachment-input';
  import beamlines_config from '@/json/beamlines';
  import { mapState } from 'vuex';

  export default {
    name: 'ceric_proposal',
    components: { attachmentInput },
    props: {
      touch_validator: {
        type: Boolean,
        default: false,
      },
      save_button_handler: {
        type: Boolean,
        default: false,
      },
      object_id: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        input_fields: {
          document_id: {
            label: 'CERIC proposal number',
            required: true,
            disabled: false,
          },
          title: {
            label: 'Title',
            value: '',
            required: true,
          },
          requested_hours: {
            label: 'Requested hours',
            required: true,
            type: 'number',
            min: 0,
          },
          shifts: {
            label: 'Granted shifts',
            required: true,
            type: 'number',
            min: 0,
          },
          main_author_name: {
            label: 'Main author\'s first name and last name (such order is required)',
            required: true,
          },
          main_author_email: {
            label: 'Main author\'s email (must be exactly the same as used by user in DUO)',
            required: true,
          },
        },
        proposal_data: {
          call_id: '',
          document_id: '',
          infrastructure: '',
          attachments: [],
          title: '',
          requested_hours: '',
          shifts: '',
          main_author_name: '',
          main_author_email: '',
        },
        is_attachment_response: true,
        is_pdf_response: true,
        validation_state: true,
        are_attachments: true,
        beamlines_config,

        // required by attachment-input
        attachment_url: '',
      };
    },
    computed: {
      ...mapState('calls', ['calls']),
      calls_options() {
        return this.calls.map((call) => ({ value: call._id.$oid, text: call.name }));
      },
    },
    validations: ceric_proposal_validator,
    watch: {
      proposal_data: {
        handler() {
          this.validation_state = !this.$v.$invalid;
          this.are_attachments = !this.$v.proposal_data.attachments.$invalid;
          this.$emit('set_validation', !this.$v.$invalid);
        },
        deep: true,
      },
      save_button_handler: {
        handler() {
          // this method is used when button 'save proposal' in modal_ceric_proposal was clicked
          if (this.save_button_handler) {
            if (this.object_id) {
              this.updateProposal();
            } else {
              this.createProposal();
            }
          }
        },
      },
    },
    methods: {
      getProposal() {
        // this method is used if proposal has been given the _id (object_id) yet
        // it returns whole proposal, which must be converted properly to be displayed
        this.axios.get(`/documents/ceric/${this.object_id}`)
          .then((resp) => {
            const { document, error } = resp.data;
            if (document) {
              this.fillProposalData(document);
            } else {
              this.$notify({ type: 'error', title: error });
            }
          });
      },
      fillProposalData(document) {
        const doc_spec = document.document_specification;
        this.proposal_data = {
          call_id: document.call_id,
          document_id: doc_spec.document_id,
          infrastructure: doc_spec.type,
          attachments: document.general.attachments,
          title: document.title,
          requested_hours: document.final_grade.requested_hours,
          shifts: document.final_grade.number_of_shifts,
          main_author_name: doc_spec.subtype.ceric.main_author_name,
          main_author_email: doc_spec.subtype.ceric.main_author_email,
        };
        if (this.proposal_data.infrastructure === 'synchrotron') {
          this.$set(this.proposal_data, 'beamlines', document.general.beamlines);
          this.$set(this.proposal_data, 'end_station', document.general.end_station);
        }
      },
      createProposal() {
        // this method is used if proposal has not been created previously
        // it creates new proposal in db
        this.axios.post('/documents/ceric', this.proposal_data)
          .then((resp) => {
            // if response from axios exists and not includes error then there is induced method 'handleSuccessProposalSave'
            // otherwise there is emitted submission_state=false which prevents modal's closure
            if (!resp) {
              this.$notify({ type: 'error', title: 'Connection error' });
              this.$emit('submission_state', false);
            } else if (!resp.data.error) {
              this.handleSuccessProposalCreate(resp.data);
            } else {
              this.$notify({ type: 'error', title: resp.data.error });
              this.$emit('submission_state', false);
            }
          });
      },
      updateProposal() {
        // this method is used if proposal has been given the _id (object_id) yet
        // it overwrites proposal data (apart from attachments)
        this.axios.put(`/documents/ceric/${this.object_id}`, this.proposal_data)
          .then((resp) => {
            // if response from axios exists and not includes error then there is induced method 'handleSuccessProposalSave'
            // otherwise there is emitted submission_state=false which prevents modal's closure
            const { error } = resp.data;
            if (!error) {
              this.handleSuccessProposalUpdate();
            } else {
              this.$notify({ type: 'error', title: error });
              this.$emit('submission_state', false);
            }
          });
      },
      async handleSuccessProposalCreate(obj_id) {
        await this.saveAttachments(obj_id);
      },
      async handleSuccessProposalUpdate() {
        await this.handlePDF(this.object_id);
      },
      async saveAttachments(document_id) {
        for (let i = 0; i < this.proposal_data.attachments.length; i += 1) {
          const el = this.proposal_data.attachments[i];
          const url = `/documents/${document_id}/attachments/${el.name}`;
          const config = { headers: { 'Content-Type': el.type } };

          // eslint-disable-next-line no-await-in-loop
          const { data: { msg } } = await this.axios.put(url, el, config) || {};
          this.is_attachment_response = msg ? this.is_attachment_response : false;
        }
        await this.handleSuccessAttachmentsSave(document_id);
      },
      async handleSuccessAttachmentsSave(obj_id) {
        if (this.is_attachment_response) {
          await this.handlePDF(obj_id);
        } else {
          this.$notify({
            type: 'error',
            title: 'Attachments cannot be saved, contact SOLARIS User Office, please',
          });
          this.$emit('submission_state', false);
        }
      },
      async handlePDF(obj_id) {
        await this.generatePdf(obj_id);
        this.handlePdfResponse();
      },
      async generatePdf(document_id) {
        this.axios.put(`/add_rendered_pdf/${document_id}`)
          .then((response) => {
            this.is_pdf_response = Object.keys(response.data)
              .includes('msg');
          })
          .catch(() => {
            this.is_pdf_response = false;
          });
      },
      handlePdfResponse() {
        if (this.is_pdf_response) {
          this.$notify({ type: 'success', title: 'Saved successful' });
          this.$emit('submission_state', true);
        } else {
          this.$notify({
            type: 'error',
            title: 'PDF cannot be generated, contact SOLARIS User Office, please',
          });
          this.$emit('submission_state', false);
        }
      },
      setInfrastructure() {
        const type_in_url = String(this.$route.path.split('/').pop());
        this.proposal_data.infrastructure = type_in_url;
      },
      customizeProposalFields() {
        if (this.proposal_data.infrastructure === 'synchrotron') {
          this.$set(this.proposal_data, 'beamlines', '');
          this.$set(this.proposal_data, 'end_station', '');
        }
      },
      watchTouchValidatorOnce() {
        // Assign watcher to destroyer and call destroyer after first watcher start.
        // This allows watcher to run only once
        this.unwatch = this.$watch(
          'touch_validator',
          () => {
            this.$v.$touch();
            this.unwatch();
          },
        );
      },
    },
    created() {
      this.watchTouchValidatorOnce();
      if (this.object_id) {
        this.attachment_url = `/documents/${this.object_id}`;
        this.input_fields.document_id.disabled = true;
        this.getProposal();
      }
      this.setInfrastructure();
      this.customizeProposalFields();
    },
  };
</script>

<style scoped>
  .invalid {
    border: 2px solid red;
  }

  .error_text {
    margin-top: 18px;
    color: red;
  }
</style>
