import { required, requiredIf } from 'vuelidate/lib/validators';
import { documentIdCericRegex, email_regex } from '@/assets/validators/regex_validator';

function isEndStationValidationPassed() {
  if (this.proposal_data.infrastructure === 'synchrotron') {
    return !this.proposal_data.end_station;
  }
  return false;
}

const numberValidation = (val) => (val ? Number.isInteger(Number(val)) : true);

const ceric_proposal_validator = {
  proposal_data: {
    call_id: { required },
    document_id: {
      required,
      lengthValidation(val) {
        if (val) {
          return (val.length === 8 && numberValidation(val)) || documentIdCericRegex.test(val);
        }
        return true;
      },
    },
    attachments: {
      required,
      lengthValidation(val) {
        if (val) {
          return val.length > 0;
        }
        return true;
      },
    },
    title: { required },
    requested_hours: {
      required,
      numberValidation,
      rangeValidation(val) {
        if (val) {
          return val >= 0;
        }
        return true;
      },
    },
    shifts: {
      required,
      numberValidation,
      rangeValidation(val) {
        if (val) {
          return val >= 0 && val < 20;
        }
        return true;
      },
    },
    main_author_name: { required },
    main_author_email: {
      required,
      regexValidation(val) {
        if (val) {
          return email_regex.regex.test(val);
        }
        return true;
      },
    },
    beamlines: { requiredIf: requiredIf(isEndStationValidationPassed) },
    end_station: { requiredIf: requiredIf(isEndStationValidationPassed) },
  },
};

export { ceric_proposal_validator };
