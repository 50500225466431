import { render, staticRenderFns } from "./modal_ceric_proposal.vue?vue&type=template&id=41a2a2fc&scoped=true&"
import script from "./modal_ceric_proposal.vue?vue&type=script&lang=js&"
export * from "./modal_ceric_proposal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41a2a2fc",
  null
  
)

export default component.exports