<template>
  <div>
    <b-modal id="modal_acceptance"
             v-bind="$attrs"
             :title="modalTitle"
             centered
             hide-footer>
      <div class="footer_btn">
        <b-btn @click="hideModal"
               v-text="'Cancel'"/>
        <b-btn :variant="okButtonVariant"
               @click="handleOk"
               v-text="okButtonText"/>
      </div>
    </b-modal>
  </div>
</template>

<script>
  export default {
    name: 'modal_acceptance',
    props: {
      modalTitle: {
        type: String,
        default: '',
      },
      okButtonText: {
        type: String,
        default: '',
      },
      okButtonVariant: {
        type: String,
        default: 'danger',
      },
    },
    methods: {
      handleOk() {
        this.hideModal();
        this.$emit('ok');
      },
      hideModal() {
        this.$nextTick(() => {
          this.$bvModal.hide('modal_acceptance');
        });
      },
    },
  };
</script>

<style lang="scss"
       scoped>
  .footer_btn {
    display: flex;
    justify-content: space-between;
  }
</style>
